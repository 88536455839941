import Layout from '@/components/ui/Layout';
import {
    Heading,
    Box,
    Center,
    Button,
    SimpleGrid,
    useToast,
} from '@chakra-ui/react';
import LoginForm from '@/components/form/LoginForm';
import Icon from '@mdi/react';
import { mdiFacebook, mdiApple } from '@mdi/js';
import facebookLogin from '@/components/context/facebookLogin';
import appleLogin from '@/components/context/appleLogin';
import { useAuthContext } from '@/components/context/authContext';
import useUser from '@/components/context/useUser';

export default function Login() {
    let { refreshToken } = useAuthContext();
    let [user, isLoadingUser, refetch] = useUser();
    const toast = useToast();
    const onFinished = () => refreshToken(() => refetch());

    return (
        <Layout
            title="Login"
            isIndex={false}
            isRaw={true}
            checkUserAuth={true}
            onlyForGuest={true}
            onlyForAuthenticatedUser={false}
        >
            <Center className="maximumheight" px={4}>
                <Box my={6} bg="white" rounded="lg" shadow="md" w="md">
                    <Heading
                        as="h1"
                        size="lg"
                        px={4}
                        py={5}
                        borderBottom="1px"
                        borderColor="gray.200"
                    >
                        Login
                    </Heading>

                    <Box px="4" pb="5">
                        <LoginForm />

                        <Box mt="6" mb="6" className="fancy">
                            <span>oder</span>
                        </Box>

                        <SimpleGrid columns={2} spacing={4} mb="2">
                            <Button
                                w="full"
                                colorScheme="facebook"
                                leftIcon={<Icon path={mdiFacebook} size={1} />}
                                onClick={() => facebookLogin(toast, onFinished)}
                            >
                                Facebook
                            </Button>
                            <Button
                                w="full"
                                bg="black"
                                color="white"
                                _hover={{ bg: 'gray.700' }}
                                leftIcon={<Icon path={mdiApple} size={1} />}
                                onClick={() => appleLogin(toast, onFinished)}
                            >
                                Apple
                            </Button>
                        </SimpleGrid>
                    </Box>
                </Box>
            </Center>
        </Layout>
    );
}
