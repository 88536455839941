import { useState } from 'react';
import { Input } from '@chakra-ui/react';
import InputWrapper from './InputWrapper';
import { InputGroup, InputRightElement, IconButton } from '@chakra-ui/react';
import Icon from '@mdi/react';
import { mdiEyeOff, mdiEye } from '@mdi/js';

export default function PasswordField({
    id,
    name,
    label,
    required,
    register,
    errors,
}) {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    return (
        <InputWrapper
            id={id}
            name={name}
            label={label}
            required={required}
            register={register}
            errors={errors}
        >
            <InputGroup>
                <Input
                    pr="2.8rem"
                    type={show ? 'text' : 'password'}
                    {...register(name, { required: true })}
                />
                <InputRightElement width="2.8rem">
                    <IconButton
                        size="sm"
                        h="1.75rem"
                        aria-label="Search database"
                        icon={
                            <Icon path={show ? mdiEyeOff : mdiEye} size={1} />
                        }
                        onClick={handleClick}
                    />
                </InputRightElement>
            </InputGroup>
        </InputWrapper>
    );
}
