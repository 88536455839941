import ActiveLink from '@/components/ActiveLink';
import { useAuthContext } from '@/components/context/authContext';
import { isDev, toastError, toastSuccess } from '@/components/helper';
import { Button, Link, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import InputField from './fields/InputField';
import PasswordField from './fields/PasswordField';

const schema = yup.object().shape({
    email: yup
        .string()
        .required('E-Mail darf nicht leer sein.')
        .email('Die E-Mail Adresse scheint fehlerhaft zu sein'),
    password: yup.string().required('Passwort darf nicht leer sein.'),
});

export default function LoginForm({ onSuccess = null }) {
    let { login } = useAuthContext();
    const toast = useToast();

    let defaultValues = isDev()
        ? {
              email: 'test@alcodo.com',
              password: 'Tester123',
          }
        : {};

    const {
        register,
        handleSubmit,
        // watch,
        // reset,
        formState: { isSubmitting, errors },
        setValue,
        getValues,
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    const onSubmit = async ({ email, password }) => {
        try {
            await login(email, password, onSuccess);
            toastSuccess(toast, 'Login erfolgreich');
        } catch (error) {
            toastError(toast, error);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
                id="email"
                name="email"
                label="E-Mail"
                required={true}
                register={register}
                errors={errors}
            />

            <PasswordField
                id="password"
                name="password"
                label="Passwort"
                required={true}
                register={register}
                errors={errors}
            />

            <ActiveLink href="/passwort-vergessen" activeClassName="active">
                <Link size="xs" mt="2" textAlign="right" display="block">
                    Passwort vergessen?
                </Link>
            </ActiveLink>

            {/* <CaptchaButton
                name="captchaToken"
                setValue={setValue}
                getValues={getValues}
                register={register}
                errors={errors}
            /> */}

            <Button
                w="100%"
                mt={4}
                colorScheme="brand"
                isLoading={isSubmitting}
                type="submit"
            >
                Anmelden
            </Button>
        </form>
    );
}
