import { makeRequest, toastError } from '@/components/helper';
import firebaseApp from '@/components/firebase/firebaseApp';
import { FacebookAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';

// https://firebase.google.com/docs/auth/web/facebook-login

export default async function facebookLogin(toast, onFinished) {
    // create provider
    const provider = new FacebookAuthProvider();
    provider.addScope('public_profile');
    provider.addScope('email');

    // start login
    const auth = getAuth(firebaseApp);

    let loginData = await signInWithPopup(auth, provider).catch(error => {
        toastError(toast, error);
        console.error('start', error);
    });

    // user has canceledd
    if (!loginData) {
        return;
    }

    // complete user table data
    await makeRequest('/user/register_complete', {
        formData: {
            firstName: loginData._tokenResponse.firstName,
            lastName: loginData._tokenResponse.lastName,
        },
    });

    await onFinished();
}
